import Responsive from '@root/core/src/utils/responsive';
import RootEnterpriseLogo from '@root/brand/src/components/root-enterprise-logo';
import RootLogo from '@root/brand/src/components/root-logo';
import SmartLink from '@root/inc.joinroot.com/src/components/smart-link';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { footerTextStyles } from '@root/inc.joinroot.com/src/components/text/text-styles';

export default function OtherCompanies() {
  return (
    <div css={styles.container}>
      <span css={[footerTextStyles, styles.title]}>Other Root Inc. Companies</span>
      <SmartLink
        css={styles.link}
        to={'https://www.joinroot.com/'}
      >
        <RootLogo logoStyles={styles.insuranceLogo} />
      </SmartLink>
      <SmartLink
        css={styles.link}
        to={'https://www.root-enterprise.com/'}
      >
        <RootEnterpriseLogo logoStyles={styles.enterpriseLogo} />
      </SmartLink>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': { marginTop: 8 },
    ...Responsive.md({
      alignItems: 'flex-end',
      '& > * + *': { marginTop: 2 },
    }),
  },
  title: {
    ...Responsive.md({
      textAlign: 'right',
    }),
  },
  link: {
    padding: 6,
    marginLeft: -4,
    '&:hover svg': {
      fill: Colors.gray40(),
    },
    '&:active svg': {
      fill: Colors.gray50(),
    },
  },
  insuranceLogo: {
    display: 'block',
    fill: Colors.white(),
    width: 'auto',
    height: 17,
    ...Responsive.md({
      height: 11,
    }),
  },
  enterpriseLogo: {
    display: 'block',
    fill: Colors.white(),
    width: 'auto',
    height: 20,
    ...Responsive.md({
      height: 13,
    }),
  },
});
