import FooterAddress from '@root/inc.joinroot.com/src/components/footer/footer-address';
import FooterDisclaimer from '@root/inc.joinroot.com/src/components/footer/footer-disclaimer';
import FooterLinks from '@root/inc.joinroot.com/src/components/footer/footer-links';
import FooterLogo from '@root/inc.joinroot.com/src/components/footer/footer-logo';
import OtherCompanies from '@root/inc.joinroot.com/src/components/footer/footer-other-companies';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function Footer({ disclaimer, footerLinks }) {
  return (
    <footer css={styles.footer}>
      <div css={styles.wrap}>
        <FooterLogo />
        <div css={styles.mainContent}>
          <FooterAddress />
          <FooterLinks footerLinks={footerLinks} />
          <OtherCompanies />
        </div>
        <FooterDisclaimer disclaimer={disclaimer} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  disclaimer: PropTypes.node,
  footerLinks: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

const styles = StyleSheet.create({
  footer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '40px 30px',
    backgroundColor: Colors.nearBlack(),
    ...Responsive.lg({
      padding: '80px 30px',
    }),
  },
  wrap: {
    width: 'min(1200px, 100%)',
  },
  mainContent: {
    ...Responsive.lessThanMd({
      margin: '60px 0 75px',
      '& > * + *': {
        marginTop: 38,
      },
    }),
    ...Responsive.md({
      display: 'flex',
      flexDirection: 'row',
      margin: '40px 0 30px',
    }),
  },
});
